import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation } from "@angular/core";
import { MatMenuPanel } from "@angular/material/menu";
import { TicketStatusesEnum } from "../../../../../../../api/acl/ticket/enums/ticket-statuses.enum";
import { Tickets } from "../../../../../../../api/acl/ticket/models/tickets.model";
import { TicketType } from "../../../../../../../api/common/enums/ticket-type.enum";

@Component({
    selector: "acl-ticket-list-item-list",
    templateUrl: "./ticket-list-item-list.template.html",
    encapsulation: ViewEncapsulation.None
})
export class TicketListItemListComponent {
    @Input()
    public tickets: Tickets[] = [];

    @Input()
    public title: string;

    @Input()
    public ticketType: TicketType;

    @Input()
    public emptyStateRef: TemplateRef<any>;

    @Input()
    public dragDrop = false;

    @Input()
    public dragDropId: string;

    @Input()
    public dragDropConnectedTo: string | string[];

    @Input()
    public dragDropSortingDisabled: boolean;

    @Input()
    public actionMenu: MatMenuPanel;

    @Output()
    public dragDropped = new EventEmitter<CdkDragDrop<Tickets>>();

    @Output()
    protected readonly onClickToggleIsArchivedTicket = new EventEmitter<Tickets>();

    @Output()
    protected readonly onClickCancelTicket = new EventEmitter<Tickets>();

    @Output()
    protected readonly onClickCompleteTicket = new EventEmitter<Tickets>();

    @Output()
    protected readonly onClickMoveTicketToToDeliver = new EventEmitter<Tickets>();

    @Output()
    protected readonly onUpdateAnticipatedPreparationDate = new EventEmitter<Tickets>();

    protected readonly TicketStatusesEnum = TicketStatusesEnum;

    public onDragDropped(event: CdkDragDrop<Tickets, Tickets>): void {
        this.dragDropped.emit(event);
    }
}
