import { Pipe, PipeTransform } from "@angular/core";
import { isPropertyDefined } from "@recursyve/nice-ts-utils";
import { TicketStatusesEnum } from "../api/acl/ticket/enums/ticket-statuses.enum";
import { Tickets } from "../api/acl/ticket/models/tickets.model";
import { ticketShippingOrderItemsHaveEquipment } from "../common/functions/ticket-shipping-order-items-have-equipment.function";

@Pipe({
    name: "ticketCanBeCanceled",
    standalone: true
})
export class TicketCanBeCanceledPipe implements PipeTransform {
    public transform(ticket: Tickets | null | undefined): boolean {
        if (!isPropertyDefined(ticket, "status")) {
            return false;
        }

        return ticket.status === TicketStatusesEnum.Waiting && !ticketShippingOrderItemsHaveEquipment(ticket);
    }
}
