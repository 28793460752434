import { RolesEnum } from "../../api/acl/account/models/enums/roles.enum";
import { TicketStatusesEnum } from "../../api/acl/ticket/enums/ticket-statuses.enum";
import { TicketType } from "../../api/common/enums/ticket-type.enum";

/*
    This logic is a 1 to 1 copy of the backend's TicketsService.getTicketStatusesForAccount(). If either this
    function or getTicketStatusesForAccount changes, they should both contains the same business logic and
    reflect the same behavior.
*/
export function getTicketStatusesForRole(role: RolesEnum, ticketType: TicketType): TicketStatusesEnum[] {
    switch (ticketType) {
        case TicketType.ShippingOrder:
            switch (role) {
                case RolesEnum.Counter:
                    return [
                        TicketStatusesEnum.Waiting,
                        TicketStatusesEnum.ToPrepare,
                        TicketStatusesEnum.InPreparation,
                        TicketStatusesEnum.Approved,
                        TicketStatusesEnum.ToDeliver
                    ];
                case RolesEnum.Preparator:
                    return [TicketStatusesEnum.ToPrepare, TicketStatusesEnum.InPreparation];
                case RolesEnum.Approver:
                    return [TicketStatusesEnum.WaitingForApproval];
                case RolesEnum.Shipping:
                    return [TicketStatusesEnum.ToDeliver];
                case RolesEnum.Admin:
                case RolesEnum.Manager:
                    return [
                        TicketStatusesEnum.Waiting,
                        TicketStatusesEnum.ToPrepare,
                        TicketStatusesEnum.InPreparation,
                        TicketStatusesEnum.WaitingForApproval,
                        TicketStatusesEnum.Approved,
                        TicketStatusesEnum.ToDeliver
                    ];
            }
            break;
        case TicketType.Batch:
            switch (role) {
                case RolesEnum.Counter:
                    return [TicketStatusesEnum.Waiting, TicketStatusesEnum.ToProduce, TicketStatusesEnum.InProduction];
                case RolesEnum.Batch:
                    return [TicketStatusesEnum.ToProduce, TicketStatusesEnum.InProduction];
                case RolesEnum.Loader:
                case RolesEnum.Shipping:
                    return [TicketStatusesEnum.Produced];
                case RolesEnum.Admin:
                case RolesEnum.Manager:
                    return [
                        TicketStatusesEnum.Waiting,
                        TicketStatusesEnum.ToProduce,
                        TicketStatusesEnum.InProduction,
                        TicketStatusesEnum.Produced
                    ];
            }
            break;
    }

    return [];
}
