export const TailwindColors = {
    Primary: "primary",
    Orange: "orange",
    BackgroundGrey: "backgroundGrey",
    ToPickup: "toPickup",
    AtClient: "atClient",
    EndOfUse: "endOfUse",
    Reserved: "reserved",
    DarkCyan: "dark-cyan",
    Yellow: "yellow",
    DarkRed: "dark-red",
    Ruby: "ruby",
    Purple: "purple",
    Green: "green",
    LightGreen: "light-green"
} as const;

export type TailwindColors = (typeof TailwindColors)[keyof typeof TailwindColors];
