import { isPropertyDefined, isPropertyDefinedCb } from "@recursyve/nice-ts-utils";
import { Tickets } from "../../api/acl/ticket/models/tickets.model";

export function ticketShippingOrderItemsHaveEquipment(ticket: Tickets | null | undefined): boolean {
    if (!isPropertyDefined(ticket, "shippingOrder") || !isPropertyDefined(ticket.shippingOrder, "shippingOrderItems")) {
        return false;
    }

    return ticket.shippingOrder.shippingOrderItems.some(isPropertyDefinedCb("equipmentId"));
}
