import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { MatMenuPanel } from "@angular/material/menu";
import { TicketStatusesEnum } from "../../../../../../../api/acl/ticket/enums/ticket-statuses.enum";
import { Tickets } from "../../../../../../../api/acl/ticket/models/tickets.model";
import { TicketType } from "../../../../../../../api/common/enums/ticket-type.enum";

@Component({
    selector: "acl-ticket-list-tile[ticket]",
    templateUrl: "./ticket-list-tile.template.html",
    styleUrls: ["ticket-list-tile.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TicketListTileComponent {
    @Input()
    public ticket: Tickets;

    @Input()
    public actionMenu: MatMenuPanel;

    @Input()
    public ticketType: TicketType;

    @Input()
    public actionMenuData: { ticket: Tickets };

    @Input()
    public selectable = false;

    @Output()
    protected readonly onClickToggleIsArchivedTicket = new EventEmitter<Tickets>();

    @Output()
    protected readonly onClickCancelTicket = new EventEmitter<Tickets>();

    @Output()
    protected readonly onClickCompleteTicket = new EventEmitter<Tickets>();

    @Output()
    protected readonly onClickMoveTicketToToDeliver = new EventEmitter<Tickets>();

    @Output()
    protected readonly onUpdateAnticipatedPreparationDate = new EventEmitter<Tickets>();

    protected readonly TicketType = TicketType;

    public get formattedBinNumber(): string {
        return this.ticket?.ticketBins?.map(b => b.bin.number).join(", ");
    }

    public get showQuantity(): boolean {
        return this.ticket?.status == TicketStatusesEnum.Produced;
    }

    public get showDensity(): boolean {
        return this.ticket?.status == TicketStatusesEnum.Produced;
    }

    public get showPoundsPerAcre(): boolean {
        return this.ticket?.status == TicketStatusesEnum.Produced;
    }
}
