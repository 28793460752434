import { Pipe, PipeTransform } from "@angular/core";
import { arePropertiesDefined } from "@recursyve/nice-ts-utils";
import { TicketStatusesEnum } from "../api/acl/ticket/enums/ticket-statuses.enum";
import { Tickets } from "../api/acl/ticket/models/tickets.model";
import { ticketShippingOrderItemsHaveEquipment } from "../common/functions/ticket-shipping-order-items-have-equipment.function";

@Pipe({
    name: "ticketCanBeArchived",
    standalone: true
})
export class TicketCanBeArchivedPipe implements PipeTransform {
    public transform(ticket: Tickets | null | undefined): boolean {
        if (!arePropertiesDefined(ticket, "isArchived", "status")) {
            return false;
        }

        return (
            !ticket.isArchived &&
            [TicketStatusesEnum.Waiting, TicketStatusesEnum.Canceled].includes(ticket.status) &&
            !ticketShippingOrderItemsHaveEquipment(ticket)
        );
    }
}
