<mat-list-item class="tile" [ngSwitch]="ticketType">
    <acl-batch-ticket-tile
        *ngSwitchCase="TicketType.Batch"
        class="flex w-full"
        [ticket]="ticket"
        [actionMenu]="actionMenu"
        [actionMenuData]="actionMenuData"
        [selectable]="selectable"
        [formattedBinNumber]="formattedBinNumber"
        [showQuantity]="showQuantity"
        [showDensity]="showDensity"
        [showPoundsPerAcre]="showPoundsPerAcre"
    ></acl-batch-ticket-tile>

    <acl-shipping-order-ticket-tile
        *ngSwitchCase="TicketType.ShippingOrder"
        class="flex w-full"
        [ticket]="ticket"
        [selectable]="selectable"
        (onClickToggleIsArchivedTicket)="onClickToggleIsArchivedTicket.emit($event)"
        (onClickCancelTicket)="onClickCancelTicket.emit($event)"
        (onClickCompleteTicket)="onClickCompleteTicket.emit($event)"
        (onClickMoveTicketToToDeliver)="onClickMoveTicketToToDeliver.emit($event)"
        (onUpdateAnticipatedPreparationDate)="onUpdateAnticipatedPreparationDate.emit($event)"
    ></acl-shipping-order-ticket-tile>
</mat-list-item>
