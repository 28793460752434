<div class="p-4 flex w-full items-center gap-8 min-h-12" [class.select-none]="!selectable">
    <div *ngIf="ticket.isArchived">
        <i class="fa-duotone fa-solid fa-box-archive text-primary icon-size-6"></i>
    </div>

    <div *ngIf="ticket.status === TicketStatusesEnum.Canceled && !ticket.isArchived">
        <i class="fa-duotone fa-solid fa-ban text-dark-red icon-size-6"></i>
    </div>

    <div
        *ngIf="(ticket | ticketHasBeenPrepared) && ticket.status !== TicketStatusesEnum.Canceled && !ticket.isArchived"
    >
        <i class="fad fa-circle-exclamation text-orange-500/75 icon-size-6"></i>
    </div>

    <div class="w-full grid grid-cols-6">
        <div class="flex flex-col gap-2">
            <span class="small-header truncate">
                {{ "ticket.list.shipping_order.shipping_order_number_and_client_code" | translate }}
            </span>
            <span class="h3 truncate">{{ ticket.batchNumber | valueOrDash }}</span>
            <span class="truncate">{{ ticket.clientId | valueOrDash }}</span>
        </div>

        <div class="flex flex-col gap-2">
            <span class="small-header truncate">{{ "ticket.list.shipping_order.locality" | translate }}</span>
            <span class="truncate">{{ ticket.client?.city | valueOrDash }}</span>

            <span *ngIf="ticket.shippingOrder?.isCollectedByClient" class="truncate text-accent font-bold">
                {{ "ticket.list.shipping_order.is_collected_by_client" | translate }}
            </span>
        </div>

        <div class="flex flex-col gap-2">
            <span class="small-header truncate">{{ "ticket.list.shipping_order.prepared_at" | translate }}</span>
            <span *ngIf="ticket.preparedAt; else dash" class="truncate">
                {{ ticket.preparedAt | localizedDate: 'longDate' }}
            </span>
            <div class="flex flex-col">
                <span class="font-bold truncate"> {{ "ticket.list.shipping_order.anticipated_on" | translate }}: </span>
                <span *ngIf="ticket.anticipatedPreparationDate; else dash">
                    {{ ticket.anticipatedPreparationDate | localizedDate: 'longDate' }}
                </span>
            </div>
        </div>

        <div class="flex flex-col gap-2">
            <span class="small-header truncate">{{ "ticket.list.shipping_order.prepared_by" | translate }}</span>
            <span class="truncate">{{ ticket.preparedBy?.fullName | valueOrDash }}</span>
        </div>

        <div class="flex flex-col gap-2">
            <span class="small-header truncate">
                {{ "ticket.list.shipping_order.required_prepared_at" | translate }}
            </span>
            <span *ngIf="ticket.requiredPreparedAt; else dash" class="truncate">
                {{ ticket.requiredPreparedAt | localizedDate: 'longDate' }}
            </span>

            <span class="font-bold truncate">{{ "ticket.list.shipping_order.shipping_notes" | translate }}:</span>
            <div class="flex flex-col">
                <span *ngIf="ticket.shippingNotes; else dash" class="line-clamp-3"> {{ ticket.shippingNotes }} </span>
            </div>
        </div>

        <div class="flex flex-col gap-2">
            <span class="small-header truncate">
                {{ "ticket.list.shipping_order.category_and_equipment" | translate }}
            </span>

            <span *ngIf="ticket.shippingOrder?.category; else dash" class="truncate">
                {{ ("models.shipping_order.categories." + ticket.shippingOrder?.category) | translate }}
            </span>
            <span class="truncate">
                {{ (ticket | firstAssociatedShippingOrderItemEquipment)?.identifier | valueOrDash }}
            </span>
        </div>
    </div>

    <div class="w-10">
        <i
            *ngIf="ticket.notes"
            class="fad fa-note-sticky text-accent icon-size-6"
            matTooltipShowDelay="100"
            [matTooltip]="ticket.notes"
        ></i>
    </div>

    <div class="w-10">
        <button
            *ngIf="
                ticket.isArchived || 
                ticket.status === TicketStatusesEnum.Approved || 
                (ticket | ticketAnticipatedPreparationDateCanBeEdited) ||
                (ticket | ticketCanBeCanceled) ||
                (ticket | ticketCanBeArchived)
            "
            niceClickStopPropagation
            mat-icon-button
            aria-label="Actions"
            [matMenuTriggerFor]="menu"
        >
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
</div>

<mat-menu #menu xPosition="before">
    <ng-container *ifRole="[RolesEnum.Counter, RolesEnum.Admin, RolesEnum.Manager]">
        <button
            *ngIf="(ticket | ticketCanBeArchived) || ticket.isArchived"
            mat-menu-item
            (click)="onClickToggleIsArchivedTicket.emit(ticket)"
        >
            <mat-icon>
                <i class="fa-regular fa-box-archive text-primary"></i>
            </mat-icon>
            <span class="text-primary">
                {{ "general." + (ticket.isArchived ? "unarchive" : "archive") | translate }}
            </span>
        </button>

        <button *ngIf="ticket | ticketCanBeCanceled" mat-menu-item (click)="onClickCancelTicket.emit(ticket)">
            <mat-icon>
                <i class="fa-regular fa-ban text-primary"></i>
            </mat-icon>
            <span class="text-primary"> {{ "general.cancel" | translate }} </span>
        </button>

        <button
            *ngIf="ticket | ticketAnticipatedPreparationDateCanBeEdited"
            mat-menu-item
            aclUpsertTicketAnticipatedPreparationDateModal
            [modalData]="{ ticketId: ticket.id }"
            (modalAfterClosed)="onUpsertTicketAnticipatedPreparationDateModalClose($event)"
        >
            <mat-icon>
                <i class="fa-regular fa-pen-to-square text-primary"></i>
            </mat-icon>
            <span class="text-primary">
                {{ "ticket.list.shipping_order.actions.update_anticipated_preparation_date" | translate }}
            </span>
        </button>

        <ng-container *ngIf="ticket.status === TicketStatusesEnum.Approved">
            <button mat-menu-item (click)="onClickCompleteTicket.emit(ticket)">
                <mat-icon>
                    <i class="fa-regular fa-circle-check text-primary"></i>
                </mat-icon>
                <span class="text-primary">{{ "ticket.list.shipping_order.actions.complete" | translate }}</span>
            </button>

            <button mat-menu-item (click)="onClickMoveTicketToToDeliver.emit(ticket)">
                <mat-icon>
                    <i class="fa-regular fa-truck text-primary"></i>
                </mat-icon>
                <span class="text-primary"
                    >{{ "ticket.list.shipping_order.actions.put_to_to_deliver" | translate }}</span
                >
            </button>
        </ng-container>
    </ng-container>
</mat-menu>

<ng-template #dash>-</ng-template>
