import {
    ChangeDetectorRef,
    Directive,
    inject,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from "@angular/core";
import { Subscription } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { roleHasAccessToTicket, TicketParams } from "../common/functions/role-has-access-to-ticket.function";
import { AppQuery } from "../store/app.query";

@Directive({
    selector: "[ifRoleHasAccessToTicket]",
    standalone: true
})
export class IfRoleHasAccessToTicketDirective implements OnInit, OnDestroy {
    private roleHasAccessToTicket$: Subscription;

    @Input("ifRoleHasAccessToTicket")
    ticketParams: TicketParams;

    private readonly appQuery = inject(AppQuery);
    private readonly templateRef = inject(TemplateRef);
    private readonly viewContainer = inject(ViewContainerRef);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    public ngOnInit(): void {
        this.roleHasAccessToTicket$ = this.appQuery
            .selectCurrentRole()
            .pipe(
                tap(() => this.viewContainer.clear()),
                filter(role => role && roleHasAccessToTicket(role, this.ticketParams))
            )
            .subscribe(() => {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.changeDetectorRef.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this.roleHasAccessToTicket$.unsubscribe();
    }
}
