import { Pipe, PipeTransform } from "@angular/core";
import { Equipments } from "../api/acl/equipments/models/equipments.model";
import { Tickets } from "../api/acl/ticket/models/tickets.model";

@Pipe({
    name: "firstAssociatedShippingOrderItemEquipment",
    standalone: true
})
export class FirstAssociatedShippingOrderItemEquipmentPipe implements PipeTransform {
    public transform(ticket: Tickets): Equipments | null {
        return (
            ticket.shippingOrder?.shippingOrderItems?.find(shippingOrderItem => shippingOrderItem.equipment)
                ?.equipment ?? null
        );
    }
}
