import { arePropertiesDefined } from "@recursyve/nice-ts-utils";
import { RolesEnum } from "../../api/acl/account/models/enums/roles.enum";
import { Tickets } from "../../api/acl/ticket/models/tickets.model";
import { getTicketStatusesForRole } from "./get-ticket-statuses-for-role.function";

export type TicketParams = Pick<Tickets, "status" | "type">;

export function roleHasAccessToTicket(role: RolesEnum | null, ticketParams: TicketParams | null): boolean {
    if (!role || !ticketParams || !arePropertiesDefined(ticketParams, "status", "type")) {
        return false;
    }

    const statuses = getTicketStatusesForRole(role, ticketParams.type);

    return statuses.includes(ticketParams.status);
}
