import { CommonModule, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { NiceStopPropagationModule } from "@recursyve/nice-ui-kit.v2";
import { RolesEnum } from "../../../../../../../../../api/acl/account/models/enums/roles.enum";
import { TicketStatusesEnum } from "../../../../../../../../../api/acl/ticket/enums/ticket-statuses.enum";
import { Tickets } from "../../../../../../../../../api/acl/ticket/models/tickets.model";
import { DirectivesModule } from "../../../../../../../../../directives/directives.module";
import { FirstAssociatedShippingOrderItemEquipmentPipe } from "../../../../../../../../../pipes/first-associated-shipping-order-item-equipment.pipe";
import { LocalizedDatePipe } from "../../../../../../../../../pipes/localized-date.pipe";
import { TicketAnticipatedPreparationDateCanBeEdited } from "../../../../../../../../../pipes/ticket-anticipated-preparation-date-can-be-edited.pipe";
import { TicketCanBeArchivedPipe } from "../../../../../../../../../pipes/ticket-can-be-archived.pipe";
import { TicketCanBeCanceledPipe } from "../../../../../../../../../pipes/ticket-can-be-canceled.pipe";
import { TicketHasBeenPreparedPipe } from "../../../../../../../../../pipes/ticket-has-been-prepared.pipe";
import { ValueOrDashPipe } from "../../../../../../../../../pipes/value-or-dash.pipe";
import { UpsertTicketAnticipatedPreparationDateModalDirective } from "../../../../../../modals/upsert-ticket-anticipated-preparation-date/upsert-ticket-anticipated-preparation-date-modal.directive";

@Component({
    selector: "acl-shipping-order-ticket-tile[ticket]",
    templateUrl: "./shipping-order-ticket-tile.template.html",
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        CommonModule,
        DirectivesModule,
        FirstAssociatedShippingOrderItemEquipmentPipe,
        LocalizedDatePipe,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatTooltipModule,
        NgIf,
        NiceStopPropagationModule,
        TicketAnticipatedPreparationDateCanBeEdited,
        TicketCanBeArchivedPipe,
        TicketCanBeCanceledPipe,
        TicketHasBeenPreparedPipe,
        TranslateModule,
        UpsertTicketAnticipatedPreparationDateModalDirective,
        ValueOrDashPipe
    ]
})
export class ShippingOrderTicketTileComponent {
    @Input()
    public ticket: Tickets;

    @Input()
    public selectable = false;

    @Output()
    protected readonly onClickToggleIsArchivedTicket = new EventEmitter<Tickets>();

    @Output()
    protected readonly onClickCancelTicket = new EventEmitter<Tickets>();

    @Output()
    protected readonly onClickCompleteTicket = new EventEmitter<Tickets>();

    @Output()
    protected readonly onClickMoveTicketToToDeliver = new EventEmitter<Tickets>();

    @Output()
    protected readonly onUpdateAnticipatedPreparationDate = new EventEmitter<Tickets>();

    protected readonly RolesEnum = RolesEnum;

    protected readonly TicketStatusesEnum = TicketStatusesEnum;

    public onUpsertTicketAnticipatedPreparationDateModalClose(ticket: Tickets | null): void {
        if (!ticket) {
            return;
        }

        this.onUpdateAnticipatedPreparationDate.emit(ticket);
    }
}
