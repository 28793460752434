import { AsyncPipe } from "@angular/common";
import { Component, inject, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FlexModule } from "@angular/flex-layout";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NiceLoadingSpinnerModule } from "@recursyve/nice-ui-kit.v2";
import { Observable, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { aclAnimations } from "../../../../../animations/acl.animations";
import { RolesEnum } from "../../../../../api/acl/account/models/enums/roles.enum";
import { TicketStatusesEnum } from "../../../../../api/acl/ticket/enums/ticket-statuses.enum";
import { Tickets } from "../../../../../api/acl/ticket/models/tickets.model";
import { TicketType } from "../../../../../api/common/enums/ticket-type.enum";
import { IfRoleHasAccessToTicketDirective } from "../../../../../directives/if-role-has-access-to-ticket.directive";
import { TicketListItemListModule } from "../ticket-list/components/item-list/ticket-list-item-list.module";
import { TicketListToDeliverQuery } from "./store/ticket-list-to-deliver.query";
import { TicketListToDeliverService } from "./store/ticket-list-to-deliver.service";
import { TicketListToDeliverStore } from "./store/ticket-list-to-deliver.store";

@Component({
    selector: "acl-ticket-list-to-deliver",
    templateUrl: "./ticket-list-to-deliver.template.html",
    styleUrls: ["ticket-list-to-deliver.style.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: aclAnimations,
    standalone: true,
    imports: [
        AsyncPipe,
        FlexModule,
        IfRoleHasAccessToTicketDirective,
        NiceLoadingSpinnerModule,
        TicketListItemListModule,
        TranslateModule
    ],
    providers: [TicketListToDeliverStore, TicketListToDeliverService, TicketListToDeliverQuery]
})
export class TicketListToDeliverComponent implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly service = inject(TicketListToDeliverService);
    private readonly query = inject(TicketListToDeliverQuery);

    protected readonly TicketType = TicketType;
    protected readonly RolesEnum = RolesEnum;

    protected readonly loading$ = this.query.selectLoading();
    protected readonly tickets$: Observable<Tickets[]> = this.query.tickets$;
    protected readonly ticketType$: Observable<TicketType> = this.route.data.pipe(
        map(data => (data.ticketType ? data.ticketType : TicketType.Batch))
    );

    protected readonly TicketStatusesEnum = TicketStatusesEnum;

    private readonly unsubscribeAll$ = new Subject<void>();

    public ngOnInit(): void {
        this.setTicketType();
        this.loadTickets();
        this.connectToGateway();
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll$.next();
        this.unsubscribeAll$.complete();
        this.service.disconnectGateway();
    }

    private setTicketType(): void {
        this.ticketType$
            .pipe(takeUntil(this.unsubscribeAll$))
            .subscribe(ticketType => this.service.setTicketType(ticketType));
    }

    private loadTickets(): void {
        this.ticketType$
            .pipe(
                switchMap(ticketType =>
                    this.service.getAllFromToday({ type: ticketType, status: [TicketStatusesEnum.ToDeliver] })
                ),
                takeUntil(this.unsubscribeAll$)
            )
            .subscribe();
    }

    private connectToGateway(): void {
        this.service.connectGateway();

        this.service
            .todayTicketsUpdated()
            .pipe(takeUntil(this.unsubscribeAll$))
            .subscribe(this.service.onTicketsUpdated.bind(this.service));

        this.service
            .todayTicketsDeleted()
            .pipe(takeUntil(this.unsubscribeAll$))
            .subscribe(this.service.onTicketsDeleted.bind(this.service));
    }
}
